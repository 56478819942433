$background-hover-color: rgba(0, 0, 0, 0.7);


.login-menu{
    -webkit-animation: fadeIn 1s !important;
    font-family: sans-serif !important;
}

.primer-nivel{
    -webkit-animation: fadeIn 1s !important;
    font-family: sans-serif !important;
}


.nivel-links-1:hover{
    background: $background-hover-color !important;
    color: white !important;
}
.nivel-links-2:hover{
    background: $background-hover-color !important;
    color: white !important;
}
.nivel-links-3:hover{
    background: $background-hover-color !important;
    color: white !important;
}

.nivel-links-3 {
  padding-left: 30px !important;
}

.active{
    background: $background-hover-color !important;
    color: white !important;
}

.button_menu:hover{
    background: $background-hover-color !important;
    color: white !important;
}

.item{
    -webkit-transition: .4s !important;
    transition: .4s !important;
    margin-bottom: 0px !important;
}

.segundo-nivel{
    margin: 0px !important;
    width: 100% !important;
}
.tercer-nivel{
    margin: 0px !important;
    width: 100% !important;
}

.segundo-nivel-not-show{
    display: none !important;
}

.segundo-nivel-show{
    display: block !important;
}

.tercer-nivel-not-show{
    display: none !important;
}

.tercer-nivel-show{
    display: block !important;
}

.segundo-nivel-login-not-show{
    display: none !important;
}

.segundo-nivel-login-show{
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    display: block !important;
}
.nivel-login-links-1:hover{
    background: $background-hover-color !important;
    color: white !important;
}
.nivel-login-links-2:hover{
    background: $background-hover-color !important;
    color: white !important;
}
.button_menu {
    margin: 4px;
    width: 44px;
    background: transparent;
    height: 34px;
    border-color: transparent;
    cursor: pointer;
    outline: none;
    vertical-align: top;
    margin-left: 14px;
    color: black;
}
.icon-bar {
    display: flex;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    background-color: #888;
    margin-bottom: 5px;
    margin-left: 3px;
    margin-top: 6px;
}
.container {
    display: contents;
}

.logo {
    width: 39px;
    height: 42px;
}

.container_logo {
    display: inline-block;
    height: 60px;
    padding-top: 9px;
}
.logo_description{
    -webkit-animation: fadeIn 1s !important;
    position: absolute;
    top: 8px;
    width: 120px;
    margin-top: 5px;
}
.ui.vertical.menu {
    //margin-top: 16px;
    margin-left: 14px;
}

.page-right{    
    width: 100%;
    @media only screen and (min-width: 1000px) {
        width: -webkit-fill-available;
        width: -moz-available;
    }
	//float: right;
    position: absolute;
    display: inline;
}

.menu-left{
    -webkit-transition: .9s !important;
    -webkit-animation: fadeIn 1s !important;
	float: left;
    position: absolute;
    z-index: 1;
    background: white;
    display: absolute;
    @media only screen and (min-width: 1000px) {
        display: inline;
    }
    margin-left: -7px !important;
    padding-right: 7px;
}

.embed-responsive-item{
    //top: 60px;
    @media only screen and (min-width: 1000px) {
        margin-left: 225px;
    }
}

.embed-responsive-item-collapsed-menu{
    //margin-left: 70px;
    //top: 60px;
}

.embed-responsive-item-elegir-intermediario{
    margin-left: 500px;
}

.elegir-intermediario{
	width: 500px;	
	height: 600px;
}

.collapsed-menu{
	width: 225px;	
	height: 100%;
}

.display-menu{
	width: 225px;	
	height: 600px;
}

.modal-blocker{
    background: black;
    width: 100%;
    z-index: 1000 !important;
    height: 100%;
    position: absolute;
    opacity: 0.5;
}

.show{
    //-webkit-animation: fadeIn 1s !important;
    display: block;
}

.not-show{
    display: none;
}

.modal-content{
    margin-top: 100px;
    position: absolute;
    opacity: 1;
    z-index: 1001;
    background: #f0f0f0;
    padding: 10px;
    //width: 40%;
    width: 60%;
    //left: 30%;
    left: 20%;
    @media only screen and (max-width: 1000px) {
        width: 94%;
        left: 3%;
    }
}

.modal-title{
    font-family: sans-serif;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.modal-body{
    font-family: sans-serif;
    color: #666;
    padding: 20px;
}
.modal-bottom{
    margin-top: 10px;
    float: right;
}

.menu-collapse{
    min-height: 540px;
}


/************selectize******/

.field-selectize{
    background: white;
    height: 38px;
    padding: 9px;
    padding-left: 14px;
    width: 100%;
    border: 1px solid rgba(34,36,38,.15);
}
.field-selectize-container{
    width: 100%;
}

.row-container-selectize{
    background: white;
    position: absolute;
    //width: 423px;
    width: 100% !important;
    margin-top: 37px;
    margin-left: 7px;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    z-index: 100;
    font-size: 12px;
    @media only screen and (max-width: 1000px) {
        font-size: 8px;
    }
    overflow-y: auto;
    max-height: 330px;
}
.error.row-container-selectize.show {
    margin-top: -41px !important;  
}

.input-selectize > .ui.input:hover{
    border-color: rgba(34,36,38,.35);
    box-shadow: none;
}


.field-selectize:hover {
    background-color: rgba(0, 0, 0, 0.7) !important;
    color: white;
    font-weight: bold;
}

.btn-x{
    cursor: pointer;
    font-size: 12px;
    float: right;
    font-weight: bold;
}

/*****************LOGIN*****************/

.login-container{
    background: #212c64;
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 0px;
    @media only screen and (min-width: 1000px) and (min-height: 600px) {
        position: fixed;
    }
    .dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer{
        display: block;
        opacity: 1;
        position: fixed;
    }
}

.logoLogin{
    margin-top: 10px;
    width: 210px;
    display: inline;
}

.imgLogin{
    width: 310px;
    border-radius: 5px 0 0 5px;
    @media only screen and (max-width: 1000px) {
        display: none;
    }
}
.logo-container{
    display: inline;
}

.message-portal{
    margin-top: 10px;
    float: right!important;
    display: inline;
    color: #fff;
    //font-weight: 800;
    font-size: 15px;
    padding: 10px 0;
    font-family: sans-serif;
}

.main-container{
    width: 60%;
    margin-left: 20% !important;
}

.form-container{
    padding: 0px !important;
    text-align: left !important;
    color: #022169;
    label{
        color: #8090b4!important;
    }
    border-radius: 5px 5px 5px 5px;
    background: #fffffa;
    margin-top: 50px;

    width: 80%;
}

.input-container{
    padding: 25px;
    display: inline;
    position: absolute;
    width: 57%;
    @media only screen and (max-width: 1000px) {
        width: 100%;
        position: relative;
    }
}

.ui.segment{
    margin-top: 35px !important;
    box-shadow: 0em !important;
    border: 0px !important;
} 

.ui.stacked.segment:after, .ui.stacked.segment:before, .ui.stacked.segments:after, .ui.stacked.segments:before{
    box-shadow: 0em !important;
    border: 0px !important;
    height: 0px !important;
}

.grid-container{
    width: 100%;
    height: '100vh';
    margin: 0px !important;
    padding: 0px !important;
}

.title-container{
    padding-left: 20px;
    padding-right: 20px;
    @media only screen and (min-width: 1000px) {
        padding-left: 200px;
        padding-right: 200px;
    }
}

.footer{
    @media only screen and (min-width: 1000px) and (min-height: 600px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    border: 0px;
    border-top: 1px solid #eee;
    margin-top: 20px !important;
    margin-bottom: 11px !important; 
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100%;
}

.footer-right{
    text-align: right;
}

.footer-center{
    text-align: -webkit-center;
    text-align: center;
}
.first-footer{
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    padding: 0px !important;
    font-size: 12px !important;
}

.last-footer{
    margin: 0px !important;
    padding: 0px !important;
    text-align: center;
    text-align: -webkit-center;
    font-size: 11px !important;
    color: #d2d2d2;
}

.subtitle-container{
    margin-left: 15px;
    label{
        font-size: 13px;
    }
}

.link-olvide-password{
    color: red;
    font-size: 10px;
    float: right;
}

.ui.icon.input>i.icon{
    cursor: pointer !important;
    z-index: 1000 !important;
    pointer-events: all !important;
}

.login-error-message{
    color: #D00019;
    font-size: 12px;
}

.error{
    color: #D00019;
    label{
        color: #D00019 !important;
    }
}

.page-loading{
    opacity: 0.5;
}
        
